// TODO: Add images alt tags
/* eslint-disable jsx-a11y/alt-text */
import { PageComponent } from '@root/pages/Type';
import { RootState } from '@root/store/types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NavigationContent from './NavigationContent';
import NavigationHeader from './NavigationHeader';

type NavigationProps = {
    currentLanguage: string | null;
    isOpen: boolean;
    open: () => void;
    close: () => void;
};

const Navigation: PageComponent<NavigationProps> = ({
    currentLanguage,
    isOpen,
    open,
    close,
}) => {
    useTranslation('landingNavigation');

    return (
        <>
            <NavigationHeader isOpen={isOpen} open={open} close={close} />

            {isOpen && (
                <NavigationContent
                    currentLanguage={currentLanguage}
                    isOpen={isOpen}
                    close={close}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        currentLanguage: state.i18n.currentLanguage,
    };
};

export default connect(mapStateToProps)(Navigation);
