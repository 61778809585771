import { default as LinkIcon } from '@static/img/icons/link.svg';
import { default as CloseIcon } from '@static/img/icons/close.svg';
import { useLiveHelp } from './LiveHelpContext';
import styles from './Footer.module.scss';
import { useEffect, FC } from 'react';
import { AppDispatch } from '@root/store/types';
import { hideAlert } from '../store/coockie/actions';
import { connect } from 'react-redux';
import Link from '@components/Link';
import { Trans } from 'react-i18next';

type FooterCoockieProps = {
    handleHideAlert: () => void;
};

const FooterCoockie: FC<FooterCoockieProps> = ({ handleHideAlert }) => {
    const { wantMoveUp } = useLiveHelp();
    useEffect(() => {
        wantMoveUp(true);
        return () => {
            wantMoveUp(false);
        };
    }, []);
    return (
        <div className={styles.coockie_section}>
            <div className={`container ${styles.coockies_inner}`}>
                <Trans i18nKey="footer.coockie.text" ns="landingHome">
                    Naršydami VPSnet.com tinklalapyje Jūs sutinkate su slapukų
                    naudojimo taisyklėmis.
                </Trans>
                <Link href="landing_information_privacy_policy">
                    <a>
                        <Trans i18nKey="footer.coockie.link" ns="landingHome">
                            Plačiau
                        </Trans>
                        <LinkIcon className={styles.icon_link} />
                    </a>
                </Link>
                <a
                    href="#"
                    className={styles.close}
                    onClick={(e) => {
                        e.preventDefault();
                        handleHideAlert();
                    }}
                >
                    <CloseIcon className={styles.icon_close} />
                </a>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleHideAlert: () => dispatch(hideAlert()),
});

export default connect(null, mapDispatchToProps)(FooterCoockie);
