// TODO: Add images alt tags
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactNode, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Link from '@components/Link';
import { Trans } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import classNames from 'classnames';
import { PageComponent } from '@root/pages/Type';
import { RootState } from '@root/store/types';
import styles from './Footer.module.scss';
import ContactForm from './FooterComponents/ContactForm';
import { default as CaretDown } from '@static/img/icons/caret-down.svg';
import { default as CaretUp } from '@static/img/icons/caret-up.svg';
import { useUser } from '@root/utils/useUser';
import FooterCoockie from './FooterCoockie';
import { LinkAuthenticated } from './HeaderContext';

type Props = {
    showAlert?: boolean;
    toTop?: boolean;
    renderTop?: ReactNode;
    renderBottom?: ReactNode;
    renderTopButton?: boolean;
    hasContactForm?: boolean;
};

const Footer: PageComponent<Props> = ({
    showAlert,
    toTop = true,
    renderTop,
    renderBottom,
    renderTopButton = false,
    hasContactForm = false,
}) => {
    const [activeCategory, setActiveCategory] = useState(0);
    const user = useUser();
    const scrollToTop = useCallback(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        scroll.scrollToTop();
    }, []);
    return (
        <>
            <div className={styles.footer}>
                {toTop && (
                    <button
                        type="button"
                        className={styles.to_top}
                        onClick={scrollToTop}
                    >
                        <CaretUp className={styles.icon_caret_up} />
                    </button>
                )}

                <div
                    className={classNames('container', styles.container, {
                        [styles.renderTopButton]: renderTopButton,
                    })}
                >
                    {renderTop}
                    {renderTopButton && (
                        <div className={`${styles.footer_text} text-center`}>
                            <h4>
                                <Trans
                                    i18nKey="registrationHero.title"
                                    ns="landingHome"
                                >
                                    Pasiruošę pradėti?
                                </Trans>
                            </h4>
                            {user !== null ? (
                                <p>
                                    <Trans
                                        i18nKey="registrationHero.descriptionUser"
                                        ns="landingHome"
                                    >
                                        Paprastas užsakymas
                                    </Trans>
                                </p>
                            ) : (
                                <p>
                                    <Trans
                                        i18nKey="registrationHero.description"
                                        ns="landingHome"
                                    >
                                        Greita ir paprasta, vos kelių žingsnių
                                        registracija
                                    </Trans>
                                </p>
                            )}
                            <LinkAuthenticated href="ui_order" showRegistration>
                                <a
                                    className={`btn btn-primary btn-lg col-sm-6 col-md-6 col-lg-4 ${styles.btn}`}
                                >
                                    {user !== null ? (
                                        <Trans
                                            i18nKey="registrationHero.orderButton"
                                            ns="landingHome"
                                        >
                                            Užsakyti dabar
                                        </Trans>
                                    ) : (
                                        <Trans
                                            i18nKey="registrationHero.registrationButton"
                                            ns="landingHome"
                                        >
                                            Registruotis
                                        </Trans>
                                    )}
                                </a>
                            </LinkAuthenticated>
                        </div>
                    )}

                    {hasContactForm && <ContactForm />}

                    <div className={`row ${styles.links}`}>
                        <div
                            className={classNames(`col-sm-12 col-lg-3`, {
                                [styles.active]: activeCategory === 0,
                            })}
                            onClick={() => setActiveCategory(0)}
                        >
                            <span>
                                <Trans
                                    i18nKey="footer.services.title"
                                    ns="landingHome"
                                >
                                    Paslaugos
                                </Trans>
                                {activeCategory === 0 ? (
                                    <CaretUp />
                                ) : (
                                    <CaretDown />
                                )}
                            </span>
                            <ul>
                                <li>
                                    <Link href="landing_services_vps">
                                        <a>
                                            <Trans
                                                i18nKey="footer.services.vps.link"
                                                ns="landingHome"
                                            >
                                                Virtualus serveris
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_services_vds">
                                        <a>
                                            <Trans
                                                i18nKey="footer.services.vds.link"
                                                ns="landingHome"
                                            >
                                                Virtualus Dedikuotas Serveris
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_services_ds">
                                        <a>
                                            <Trans
                                                i18nKey="footer.services.ds.link"
                                                ns="landingHome"
                                            >
                                                Dedikuotas serveris
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_services_colocation">
                                        <a>
                                            <Trans
                                                i18nKey="footer.services.colocation.link"
                                                ns="landingHome"
                                            >
                                                Serverių kolokacija
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        href="//www.speedy.lt/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Trans
                                            i18nKey="footer.services.webhost.link"
                                            ns="landingHome"
                                        >
                                            Svetainių talpinimas
                                        </Trans>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="//www.speedy.lt/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Trans
                                            i18nKey="footer.services.domains.link"
                                            ns="landingHome"
                                        >
                                            Domenų registracija
                                        </Trans>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`col-sm-12 col-lg-3 ${
                                activeCategory === 1 ? styles.active : ''
                            }`}
                            onClick={() => setActiveCategory(1)}
                        >
                            <span>
                                <Trans
                                    i18nKey="footer.features.title"
                                    ns="landingHome"
                                >
                                    Privalumai
                                </Trans>
                                {activeCategory === 1 ? (
                                    <CaretUp />
                                ) : (
                                    <CaretDown />
                                )}
                            </span>
                            <ul>
                                <li>
                                    <Link href="landing_services_reasons_technology">
                                        <a>
                                            <Trans
                                                i18nKey="footer.features.reasonsTechnology.link"
                                                ns="landingHome"
                                            >
                                                Prežastys ir technologija
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_services_reasons_hardware">
                                        <a>
                                            <Trans
                                                i18nKey="footer.features.hardware.link"
                                                ns="landingHome"
                                            >
                                                Techninė įranga
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_services_reasons_control">
                                        <a>
                                            <Trans
                                                i18nKey="footer.features.controlPanel.link"
                                                ns="landingHome"
                                            >
                                                Patogi valdymo panele
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`col-sm-12 col-lg-3 ${
                                activeCategory === 2 ? styles.active : ''
                            }`}
                            onClick={() => setActiveCategory(2)}
                        >
                            <span>
                                <Trans
                                    i18nKey="footer.information.title"
                                    ns="landingHome"
                                >
                                    Informacija
                                </Trans>
                                {activeCategory === 2 ? (
                                    <CaretUp />
                                ) : (
                                    <CaretDown />
                                )}
                            </span>
                            <ul>
                                <li>
                                    <Link href="landing_services_about_company">
                                        <a>
                                            <Trans
                                                i18nKey="footer.information.aboutCompany.link"
                                                ns="landingHome"
                                            >
                                                Apie kompanija
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                {false && (
                                    <li>
                                        <a href="#">'Looking Glass'</a>
                                    </li>
                                )}
                                <li>
                                    <Link href="landing_services_loyalty">
                                        <a>
                                            <Trans
                                                i18nKey="footer.information.loyalty.link"
                                                ns="landingHome"
                                            >
                                                Lojalumas
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_information_term_of_service">
                                        <a>
                                            <Trans
                                                i18nKey="footer.information.loyalty.termOfService"
                                                ns="landingHome"
                                            >
                                                Paslaugų teikimo sąlygos
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_information_privacy_policy">
                                        <a>
                                            <Trans
                                                i18nKey="footer.information.loyalty.privacyPolicy"
                                                ns="landingHome"
                                            >
                                                Privatumo politika
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_information_refund_policy">
                                        <a>
                                            <Trans
                                                i18nKey="footer.information.loyalty.refundPolicy"
                                                ns="landingHome"
                                            >
                                                Paslaugų gražinimo politika
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`col-sm-12 col-lg-3 ${
                                activeCategory === 3 ? styles.active : ''
                            }`}
                            onClick={() => setActiveCategory(3)}
                        >
                            <span>
                                <Trans
                                    i18nKey="footer.help.title"
                                    ns="landingHome"
                                >
                                    Pagalba
                                </Trans>
                                {activeCategory === 3 ? (
                                    <CaretUp />
                                ) : (
                                    <CaretDown />
                                )}
                            </span>
                            <ul>
                                <li>
                                    <Link href="landing_services_faq">
                                        <a>
                                            <Trans
                                                i18nKey="footer.help.faq.link"
                                                ns="landingHome"
                                            >
                                                D.U.K.
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_live_help">
                                        <a>
                                            <Trans
                                                i18nKey="footer.help.liveHelp.link"
                                                ns="landingHome"
                                            >
                                                Pagalba gyvai
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="landing_contacts">
                                        <a>
                                            <Trans
                                                i18nKey="footer.help.contacts.link"
                                                ns="landingHome"
                                            >
                                                Kontaktai
                                            </Trans>
                                        </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.copyright}>
                        <div className={styles.brand}>
                            <img src="/static/img/logos/logo.svg" />
                        </div>
                        <div className={styles.social}>
                            <a
                                href="https://twitter.com/infovpsnet"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="/static/img/icons/social/twitter.svg" />
                            </a>
                            <a
                                href="https://fb.me/infoVPSnet"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="/static/img/icons/social/facebook.svg" />
                            </a>
                            <a
                                href="https://www.instagram.com/infovpsnet/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="/static/img/icons/social/instagram.svg" />
                            </a>
                        </div>
                    </div>
                    {hasContactForm && (
                        <img
                            src="/static/img/home-top-2.png"
                            className={styles.footer_animation}
                        />
                    )}
                </div>
                {renderBottom}
            </div>

            {showAlert && <FooterCoockie />}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    showAlert: state.coockie.showAlert,
});

export default connect(mapStateToProps, null)(Footer);
