import { PageComponent } from '@root/pages/Type';
import Navigation from './Navigation';
import useBodyClass from '@root/utils/useBodyClass';
import { useState } from 'react';

type HeaderProps = {
    isOpen?: boolean;
};

const Header: PageComponent<HeaderProps> = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen || false);
    useBodyClass(isOpen ? 'fixed_body' : undefined);
    return (
        <Navigation
            isOpen={isOpen}
            open={() => setIsOpen(true)}
            close={() => {
                setIsOpen(false);
            }}
        />
    );
};
export default Header;
