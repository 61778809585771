import { useState, useEffect } from 'react';
import { SuccessIcon } from '@components/Ui/Icons/Index';
import { ContactFormSchemaInit } from '@utils/ValidationSchemas/Index';
import { useTranslation, Trans } from 'react-i18next';
import PrettyError from '@utils/errors/PrettyError';
import { PageComponent } from '@pages/Type';
import withForm, { withFormProps } from '@utils/withForm';
import { Field, Textarea, Captcha, Button } from '@components/Form/Index';
import styles from './ContactForm.module.scss';
import { useCaptcha } from '../CaptchaProvider';
import getConfig from 'next/config';
import { useFormRequest } from '@root/utils/useFormRequest';
const { publicRuntimeConfig } = getConfig();

type ContactFormValues = {
    contactName: string;
    contactEmail: string;
    contactMessage: string;
    recaptcha: string;
};

type ContactFormProps = withFormProps<ContactFormValues> & {};

const initialValues: ContactFormValues = {
    contactName: '',
    contactEmail: '',
    contactMessage: '',
    recaptcha: '',
};

const ContactForm: PageComponent<ContactFormProps> = ({
    errorMessage,
    isValid,
    submitting,
    handleForm,
}) => {
    const captcha = useCaptcha();
    const [showConfirm, setShowConfirm] = useState(false);
    const { t } = useTranslation('landingContactForm');
    const { setVisible } = captcha;

    const { formHandle } = useFormRequest<ContactFormValues>({
        url: `${publicRuntimeConfig.API_URL}/public/contactForm`,
        captcha,
    },
        (response) => {
            if (!response.success) {
                throw new PrettyError(
                    t('form.fatalError', {
                        defaultValue:
                            'Nepavyko pateikti žinutės. Prašome mėginti dar kartą.',
                    }),
                );
            }
            setShowConfirm(true);
        },
        t('form.fatalError', {
            defaultValue:
                'Nepavyko pateikti žinutės. Prašome mėginti dar kartą.',
        }));

    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    }, []);

    return (
        <>
            <div className={styles.footer_contact_form} id="contact-form">
                <p className="text-center">
                    <Trans i18nKey="text" ns="landingContactForm">
                        Sunku issirinkti? Ar tiesiog norite pagalbos?
                        Susisiekite su mumis
                    </Trans>
                </p>
                {errorMessage && (
                    <p className={styles.form_error}>
                        <Trans i18nKey="form.error" ns="landingContactForm" values={{ errorMessage }}>
                            Klaida: [[errorMessage]]
                        </Trans>
                    </p>
                )}
                {showConfirm && (
                    <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
                        <SuccessIcon />
                        <p className="text-center text-success">
                            <Trans i18nKey="submmited" ns="landingContactForm">
                                Zinute issiuta!
                            </Trans>
                        </p>
                    </div>
                )}
                {!showConfirm && (
                    <form
                        className="col-sm-12 col-md-10 col-lg-6 m-auto"
                        onSubmit={handleForm(formHandle)}
                    >
                        <div className="form-row">
                            <div className={`form-group form-field col-sm-12 col-md-6 col-lg-6 ${styles.name}`}>
                                <Field
                                    name="contactName"
                                    label={t('form.firstName', {
                                        defaultValue: 'Vardas',
                                    })}
                                    type="text"
                                    disabled={submitting}
                                />
                            </div>
                            <div className={`form-group form-field col-sm-12 col-md-6 col-lg-6 ${styles.email}`}>
                                <Field
                                    name="contactEmail"
                                    label={t('form.email', {
                                        defaultValue: 'El. pastas',
                                    })}
                                    type="text"
                                    disabled={submitting}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className={`form-group col-12 ${styles.message}`}>
                                <Textarea
                                    name="contactMessage"
                                    label={t('form.message', {
                                        defaultValue: 'Zinute',
                                    })}
                                    disabled={submitting}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mx-auto mb-3 mb-md-0">
                                <Captcha className={styles.recaptcha} />
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 m-auto text-lg-right">
                                <Button
                                    type="submit"
                                    className={`btn btn-tertiary btn-lg ${styles.submit}`}
                                    disabled={!isValid}
                                    isLoading={submitting}
                                >
                                    <Trans
                                        i18nKey="submit"
                                        ns="landingContactForm"
                                    >
                                        Siusti
                                    </Trans>
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default withForm({
    initialValues,
    validationSchema: () => ContactFormSchemaInit(),
})(ContactForm);
