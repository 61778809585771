import { useEffect } from 'react';

const addBodyClass = (className: string) =>
    document.body.classList.add(className);
const removeBodyClass = (className: string) =>
    document.body.classList.remove(className);

export default function useBodyClass(className?: string | Array<string>) {
    useEffect(() => {
        // Set up
        if (typeof className !== 'undefined') {
            className instanceof Array
                ? className.map(addBodyClass)
                : addBodyClass(className);
        }

        // Clean up
        return () => {
            if (typeof className !== 'undefined') {
                className instanceof Array
                    ? className.map(removeBodyClass)
                    : removeBodyClass(className);
            }
        };
    }, [className]);
}
