// TODO: Add images alt tags
/* eslint-disable jsx-a11y/alt-text */

import { PageComponent } from '@root/pages/Type';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import LinkToAuthenticate from './Auth/LinkToAuthenticate';
import Link from './Link';
import { useLiveHelp } from './LiveHelpContext';
import styles from './Navigation.module.scss';

type NavigationContentProps = {
    currentLanguage: string | null;
    isOpen: boolean;
    close: () => void;
};

const NavigationContent: PageComponent<NavigationContentProps> = ({
    currentLanguage,
    isOpen,
    close,
}) => {
    const router = useRouter();
    const { wantHide } = useLiveHelp();
    useEffect(() => {
        wantHide(true);
        return () => {
            wantHide(false);
        };
    }, []);

    return (
        <div
            className={`${styles.navigation} ${
                isOpen ? styles.open_navigation : ''
            }`}
        >
            <div className={styles.navigation_inner}>
                <LinkToAuthenticate href="auth_login" shallowBack passHref>
                    <a
                        className={`btn btn-tertiary ${styles.nav_user} d-lg-none mb-3`}
                    >
                        <span>
                            <Trans
                                i18nKey="clientZoneButton"
                                ns="landingNavigation"
                            >
                                Klientų zona
                            </Trans>
                        </span>
                    </a>
                </LinkToAuthenticate>
                <LinkToAuthenticate
                    href="auth_registration"
                    shallowBack
                    passHref
                >
                    <a
                        className={`btn btn-primary ${styles.nav_register} d-lg-none`}
                    >
                        <Trans
                            i18nKey="registrationButton"
                            ns="landingNavigation"
                        >
                            Registracija
                        </Trans>
                    </a>
                </LinkToAuthenticate>
                <h4>
                    <Trans
                        i18nKey="servicesTitle.servers"
                        ns="landingNavigation"
                    >
                        Serveriai
                    </Trans>
                </h4>
                <ul className={styles.links}>
                    <li>
                        <Link href="landing_services_vps">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_vps'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <img src="/static/img/icons/services/vps-selected.png" />
                                <span>
                                    <span>
                                        <Trans
                                            i18nKey="services.vps"
                                            ns="common"
                                        >
                                            Virtualus Privatus Serveris
                                        </Trans>
                                    </span>
                                    <i className="icon-link" />
                                </span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="landing_services_vds">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_vds'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <img src="/static/img/icons/services/vds-selected.png" />
                                <span>
                                    <span>
                                        <Trans
                                            i18nKey="services.vds"
                                            ns="common"
                                        >
                                            Virtualus Dedikuotas Serveris
                                        </Trans>
                                    </span>
                                    <i className="icon-link" />
                                </span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="landing_services_ds">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_ds'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <img src="/static/img/icons/services/ds-selected.png" />
                                <span>
                                    <span>
                                        <Trans
                                            i18nKey="services.ds"
                                            ns="common"
                                        >
                                            Dedikuoti Serveris
                                        </Trans>
                                    </span>
                                    <i className="icon-link" />
                                </span>
                            </a>
                        </Link>
                    </li>
                </ul>
                <h4>
                    <Trans
                        i18nKey="servicesTitle.others"
                        ns="landingNavigation"
                    >
                        Kitos paslaugos
                    </Trans>
                </h4>
                <ul className={styles.links}>
                    <li>
                        <Link href="landing_services_colocation">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_colocation'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <img src="/static/img/icons/services/colocation.svg" />
                                <span>
                                    <span>
                                        <Trans
                                            i18nKey="services.colocation"
                                            ns="common"
                                        >
                                            Serverių Kolokacija
                                        </Trans>
                                    </span>
                                    <i className="icon-link" />
                                </span>
                            </a>
                        </Link>
                    </li>
                    {currentLanguage === 'lt' && (
                        <li>
                            <a
                                href="//www.speedy.lt/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <>
                                    <img src="/static/img/icons/services/web-hosting.svg" />
                                    <span>
                                        <span>
                                            <Trans
                                                i18nKey="services.webhost"
                                                ns="common"
                                            >
                                                Svetainių Talpinimas
                                            </Trans>
                                        </span>
                                        <i className="icon-link" />
                                    </span>
                                </>
                            </a>
                        </li>
                    )}
                    {currentLanguage === 'lt' && (
                        <li>
                            <a
                                href="//www.speedy.lt/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="/static/img/icons/services/domains.svg" />
                                <span>
                                    <span>
                                        <Trans
                                            i18nKey="services.domains"
                                            ns="common"
                                        >
                                            Domenų Registravimas
                                        </Trans>
                                    </span>
                                    <i className="icon-link" />
                                </span>
                            </a>
                        </li>
                    )}
                </ul>
                <ul className={styles.sub_links}>
                    <li>
                        <Link href="landing_services_reasons_technology">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_reasons_technology'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <Trans
                                    i18nKey="servicesTitle.links.features"
                                    ns="landingNavigation"
                                >
                                    Privalumai
                                </Trans>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="landing_services_about_company">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_about_company'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <Trans
                                    i18nKey="servicesTitle.links.aboutus"
                                    ns="landingNavigation"
                                >
                                    Apie mus
                                </Trans>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href="landing_services_faq">
                            <a
                                onClick={() => {
                                    if (
                                        router.query.nextRoute ==
                                        'landing_services_faq'
                                    ) {
                                        close();
                                    }
                                }}
                            >
                                <Trans
                                    i18nKey="servicesTitle.links.help"
                                    ns="landingNavigation"
                                >
                                    Pagalba
                                </Trans>
                            </a>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default NavigationContent;
