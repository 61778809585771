import { PageComponent } from '@root/pages/Type';
import { useRouter } from 'next/router';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Link, { LinkProps } from '@root/components/Link';
import { useSelector } from 'react-redux';
import { RootState } from '@root/store/types';
import { RouteHref, Router } from '@root/router';
import LinkToAuthenticate from './Auth/LinkToAuthenticate';

type HeaderContextType = {
    isOpenLogin: boolean;
    isOpenPasswordReset: boolean;
    isOpenNav: boolean;
    isOpenSocial: boolean;
    returnToLogin: string | null;
    socialLoginCode: string | null;
    openNav: () => void;
    closeNav: () => void;
    openLogin: () => void;
    closeLogin: () => void;
    openPasswordReset: () => void;
    closePasswordReset: () => void;
    openSocial: () => void;
    closeSocial: () => void;
    closeFull: () => void;
    removeSocialCode: () => void;
};

enum HeaderQuery {
    LOGIN = 'showLogin',
    SOCIAL = 'socialLogin',
    REGISTER = 'showRegistration',
    RETURN_TO = 'returnTo',
    SOCIAL_CODE = 'code',
    PASSWORD = 'passwordRemind',
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

const HeaderProvider: PageComponent = ({ children }) => {
    const router = useRouter();
    const [showNav, setShowNav] = useState(false);
    const { currentLanguage, user } = useSelector((state: RootState) => ({
        user: state.auth.user,
        currentLanguage: state.i18n.currentLanguage,
    }));

    const [query, setQuery] = useState(router.query);

    const updateQuery = useCallback(
        (add: Record<string, any>, remove: Array<string> = []) => {
            const _query = query;
            for (const item in add) {
                _query[item] = add[item];
            }
            if (remove.length > 0) {
                for (const rItem of remove) {
                    if (rItem in _query) {
                        delete _query[rItem];
                    }
                }
            }
            const { nextRoute, __nextLocale, ...queryParams } = _query;
            Router.pushRoute(
                nextRoute as string,
                queryParams,
                currentLanguage,
                {
                    shallow: true,
                    locale: currentLanguage,
                },
            );
        },
        [query],
    );

    useEffect(() => {
        setQuery(router.query);
    }, [router.query]);

    const showLogin = useMemo(() => {
        if (!(HeaderQuery.LOGIN in query)) {
            return false;
        }
        if (Array.isArray(query[HeaderQuery.LOGIN])) {
            return false;
        }
        return !!query[HeaderQuery.LOGIN];
    }, [query]);

    const showPasswordReset = useMemo(() => {
        if (!(HeaderQuery.PASSWORD in query)) {
            return false;
        }
        if (Array.isArray(query[HeaderQuery.PASSWORD])) {
            return false;
        }
        return !!query[HeaderQuery.PASSWORD];
    }, [query]);

    const showSocial = useMemo(() => {
        if (!(HeaderQuery.SOCIAL in query)) {
            return false;
        }
        if (Array.isArray(query[HeaderQuery.SOCIAL])) {
            return false;
        }
        return !!query[HeaderQuery.SOCIAL];
    }, [query]);

    const showUserRegistration = useMemo(() => {
        if (!(HeaderQuery.REGISTER in query)) {
            return false;
        }
        if (Array.isArray(query[HeaderQuery.REGISTER])) {
            return false;
        }
        return !!query[HeaderQuery.REGISTER];
    }, [query]);

    const returnToLogin = useMemo(() => {
        if (!(HeaderQuery.RETURN_TO in query)) {
            return null;
        }
        if (Array.isArray(query[HeaderQuery.RETURN_TO])) {
            return null;
        }
        return query[HeaderQuery.RETURN_TO] as string;
    }, [query]);

    const socialLoginCode = useMemo(() => {
        if (!(HeaderQuery.SOCIAL_CODE in query)) {
            return null;
        }
        if (Array.isArray(query[HeaderQuery.SOCIAL_CODE])) {
            return null;
        }
        if (!showLogin && !showUserRegistration) {
            return null;
        }
        return query[HeaderQuery.SOCIAL_CODE] as string;
    }, [query, showLogin, showUserRegistration]);

    const closeFull = useCallback(() => {
        setShowNav(false);

        return updateQuery({}, [
            HeaderQuery.LOGIN,
            HeaderQuery.REGISTER,
            HeaderQuery.SOCIAL,
            HeaderQuery.SOCIAL_CODE,
            HeaderQuery.RETURN_TO,
            HeaderQuery.PASSWORD,
        ]);
    }, [updateQuery]);

    const openNav = useCallback(() => {
        setShowNav(true);
    }, [updateQuery]);
    const closeNav = useCallback(() => {
        setShowNav(false);
    }, [updateQuery]);

    const openPasswordReset = useCallback(
        () => updateQuery({ [HeaderQuery.PASSWORD]: true }),
        [updateQuery],
    );
    const closePasswordReset = useCallback(
        () => updateQuery({}, [HeaderQuery.PASSWORD]),
        [updateQuery],
    );

    const openSocial = useCallback(
        () => updateQuery({ [HeaderQuery.SOCIAL]: true }),
        [updateQuery],
    );

    const closeSocial = useCallback(
        () => updateQuery({}, [HeaderQuery.SOCIAL, HeaderQuery.SOCIAL_CODE]),
        [updateQuery],
    );

    const openLogin = useCallback(
        () => updateQuery({ [HeaderQuery.LOGIN]: true }),
        [updateQuery],
    );
    const closeLogin = useCallback(
        () => updateQuery({}, [HeaderQuery.LOGIN]),
        [updateQuery],
    );

    const openUserRegistration = useCallback(
        () => updateQuery({ [HeaderQuery.REGISTER]: true }),
        [updateQuery],
    );
    const closeUserRegistration = useCallback(
        () => updateQuery({}, [HeaderQuery.REGISTER]),
        [updateQuery],
    );

    const removeSocialCode = useCallback(
        () => updateQuery({}, [HeaderQuery.SOCIAL_CODE]),
        [updateQuery],
    );

    const values = useMemo(
        () => ({
            closeFull,
            isOpenSocial: showSocial,
            openSocial,
            closeSocial,
            isOpenNav: showNav,
            openNav,
            closeNav,
            isOpenLogin: showLogin,
            openLogin,
            closeLogin,
            isOpenPasswordReset: showPasswordReset,
            openPasswordReset,
            closePasswordReset,
            isOpenUserRegistration: showUserRegistration,
            openUserRegistration,
            closeUserRegistration,
            returnToLogin,
            socialLoginCode,
            removeSocialCode,
        }),
        [
            closeFull,
            showSocial,
            openSocial,
            closeSocial,
            showNav,
            openNav,
            closeNav,
            showLogin,
            openLogin,
            closeLogin,
            showPasswordReset,
            openPasswordReset,
            closePasswordReset,
            showUserRegistration,
            openUserRegistration,
            closeUserRegistration,
            returnToLogin,
            socialLoginCode,
            removeSocialCode,
        ],
    );

    useEffect(() => {
        if (
            (showLogin || showUserRegistration) &&
            user !== null &&
            returnToLogin != null
        ) {
            Router.push(returnToLogin);
        } else if (
            (showLogin || showUserRegistration) &&
            user !== null &&
            returnToLogin === null
        ) {
            Router.pushRoute('ui_services_list', {}, currentLanguage);
        }
    }, [user, returnToLogin, showLogin, showUserRegistration]);

    return (
        <HeaderContext.Provider value={values}>
            {children}
        </HeaderContext.Provider>
    );
};

const useHeaderContext = (): HeaderContextType => {
    const context = useContext(HeaderContext);
    if (context === undefined) {
        throw new Error(
            'useHeaderContext must be used within a HeaderProvider',
        );
    }
    return context;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const withHeaderProvider = <P extends {}>(
    WrappedComponent: React.ComponentType<P>,
) => {
    const Wrapper: React.FC<P> = (props) => {
        return (
            <HeaderProvider>
                <WrappedComponent {...props} />
            </HeaderProvider>
        );
    };
    if (WrappedComponent.defaultProps) {
        Wrapper.defaultProps = WrappedComponent.defaultProps;
    }
    Wrapper.displayName = `withHeaderProvider(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    return Wrapper;
};

type LinkAuthenticatedProps = LinkProps & {
    href: string;
    showRegistration?: boolean;
};

const LinkAuthenticated: PageComponent<LinkAuthenticatedProps> = ({
    children,
    href,
    params,
    showRegistration = false,
    ...props
}) => {
    const router = useRouter();
    const authedUser = useSelector((state: RootState) => state.auth.user);
    const currentLanguage = useSelector(
        (state: RootState) => state.i18n.currentLanguage,
    );
    if (authedUser === null) {
        if (typeof router.query.nextRoute !== 'string') {
            return null;
        }
        let { nextRoute, ...query } = router.query;
        let linkParams: Record<string, string> = {
            returnTo: RouteHref(href, currentLanguage, params),
            backTo: RouteHref(nextRoute, currentLanguage, query),
        };
        let linkHref = 'auth_login';
        if (showRegistration) {
            linkHref = 'auth_registration';
        }
        return (
            <LinkToAuthenticate
                href={linkHref}
                params={linkParams}
                authenticatedHref={href}
                authenticatedParams={params}
                {...props}
            >
                {children}
            </LinkToAuthenticate>
        );
    }
    return (
        <Link href={href} params={params} {...props}>
            {children}
        </Link>
    );
};

export default HeaderProvider;
export {
    HeaderContext,
    useHeaderContext,
    withHeaderProvider,
    LinkAuthenticated,
};
